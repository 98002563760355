<template>
  <section class="price">
    <h1>Раздел в разработке :)</h1>
    <!-- <AddPriceItemsFromXLS />
    <AddDetailsByName /> -->
  </section>
</template>

<script>
// import AddDetailsByName from "@/components/MarketingComponents/price/AddDetailsByName";
// import AddPriceItemsFromXLS from "@/components/MarketingComponents/price/AddPriceItemsFromXLS";
export default {
  components: {
    // AddDetailsByName,
    // AddPriceItemsFromXLS,
  },
};
</script>

<style lang="scss" scoped>
</style>